import { Controller } from '@hotwired/stimulus';
import {createCallback, getRenderDefaults, onItemAddDefault} from "../../components/tomselect2";

export default class extends Controller {
    initialize() {
        this._onPreConnect = this._onPreConnect.bind(this);
        this._onConnect = this._onConnect.bind(this);
    }

    connect() {
        this.element.addEventListener('autocomplete:pre-connect', this._onPreConnect);
        this.element.addEventListener('autocomplete:connect', this._onConnect);
    }

    disconnect() {
        this.element.removeEventListener('autocomplete:connect', this._onConnect);
        this.element.removeEventListener('autocomplete:pre-connect', this._onPreConnect);
    }

    _onPreConnect(event) {
        let options = event.detail.options;

        options.render = getRenderDefaults();
        options.onItemAdd = onItemAddDefault();
        options.create = createCallback('/reference/create/geo', {}, 'value', 'text');

        event.detail.options = options;
    }

    _onConnect(event) {
        console.log(event.detail.tomSelect);
        console.log(event.detail.options);
    }
}