import {Controller} from "@hotwired/stimulus";
import {requestFullScreen} from "../../components/fullscreen";

export default class extends Controller
{
    static values = {
        selector: String,
    }

    connect() {
    }

    requestFullscreen() {
        requestFullScreen(document.querySelector(this.selectorValue));
    }

    disconnect() {
    }
}