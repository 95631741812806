import {Controller} from "@hotwired/stimulus";
import TomSelect from "tom-select";
import $ from "jquery";
import {createCallback, getPluginsDefaults, getRenderDefaults} from "../../components/tomselect2";

export default class extends Controller {
    analyticsCounters;

    connect() {
        this.blocksController();
        this.analyticsController();

        this.budgetCurrency = new TomSelect('.interactive-select-currency', {
            create: createCallback('/reference/create/currency'),
            render: getRenderDefaults(),
            plugins: getPluginsDefaults(),
        });

        this.kpiType = new TomSelect('.interactive-select-kpi_type', {
            create: createCallback('/reference/create/kpi_type'),
            render: getRenderDefaults(),
            plugins: getPluginsDefaults(),
        });

        this.marketPlace = new TomSelect('.interactive-select-marketplace', {
            create: createCallback('/reference/create/marketplace'),
            render: getRenderDefaults(),
            plugins: getPluginsDefaults(),
        });

        const self = this;

        this.projectSelect = new TomSelect('.interactive-select-project', {
            render: getRenderDefaults(),
            plugins: getPluginsDefaults(),
        });
    }

    disconnect() {
        this.budgetCurrency.destroy();
        this.kpiType.destroy();
        this.marketPlace.destroy();
        this.projectSelect.destroy();

        if (this.analyticsCounters) {
            this.analyticsCounters.destroy();
        }
    }

    blocksController() {
        const promotionGoal = document.getElementById('brief_promotionGoal');

        if (promotionGoal !== null) {
            showBlock(promotionGoal.value);

            promotionGoal.onchange = function () {
                showBlock(this.value);
            }
        }

        function showBlock(value) {
            document.querySelectorAll('.promotion-goal-block').forEach(function (item) {
                item.classList.add('d-none');
            });

            document.querySelectorAll('.promotion-goal-block-' + value).forEach(function (item) {
                item.classList.remove('d-none');
            });
        }
    }

    analyticsController() {
        const ANALYTICS_COUNTER_END_TO_END_ANALYTICS_SYSTEM = '3';
        const self = this;

        this.analyticsCounters = new TomSelect('.analytics-counters', {
            plugins: getPluginsDefaults(),
            onChange: function () {
                updateBlocks();
            }
        });

        updateBlocks();

        function updateBlocks() {
            const value = self.analyticsCounters.getValue();
            const block = $('.analytics-end-to-end-block');
            const attributes = $('.analytics-attributes');

            if (value.indexOf(ANALYTICS_COUNTER_END_TO_END_ANALYTICS_SYSTEM) > -1) {
                block.removeClass('d-none');
            } else {
                block.addClass('d-none');
            }

            if (value.length > 0) {
                attributes.removeClass('d-none')
            } else {
                attributes.addClass('d-none');
            }
        }
    }
}