import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static get targets () {
        return [
            'row'
        ];
    }

    change(event) {
        const value = event.target.value.toLowerCase();

        this.rowTargets.forEach((row) => {
            const name = row.dataset['name'].toLowerCase();

            row.classList.remove('d-none');

            if (value !== '' && !name.includes(value)) {
                row.classList.add('d-none');
            }
        })
    }
}