import { Controller } from "@hotwired/stimulus";
import Modal from "../../components/modal";

export default class extends Controller {
    confirm(event) {
        event.preventDefault();

        const { status, title, id } = event.params;

        let description = 'Действительно хотите <strong>Одобрить</strong> бриф "' + title + '" и взять его в работу?';

        if (!status) {
            description = 'Действительно хотите <strong>Отклонить</strong> бриф "' + title + '" и вернуть его на доработку?';
        }

        const confirmModal = new Modal('Подтверждение', description, {
            yesLabel: status ? 'Принять' : 'Отклонить',
            type: status ? 'confirm' : 'confirm_with_comment',
            callback: async function (comment) {
                let response = await fetch('/brief/confirm/'+id, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ status: status, comment: comment })
                });

                if (response.status === 204) {
                    document.location.reload();
                } else {
                    const text = await response.text();
                    (new Modal('Ошибка!', text, { type: "alert"})).show();
                }
            }
        });

        confirmModal.show();
    }
}