import {Controller} from '@hotwired/stimulus';
import Preloader from '../../components/preloader';
import {sheetScrollUpdate, sheetZoomUpdate} from "../../components/luckysheet/sheetupdater";

export default class extends Controller {
    static get targets () {
        return ['spreadsheet'];
    }

    static values = {
        sheetId: String,
    }

    connect() {
        Preloader.create();
        const self = this;

        const options = {
            container: this.spreadsheetTarget.id,
            forceCalculation: true,
            allowUpdate: true,
            hook: {
                cellUpdateBefore: function() {
                    return false;
                },
                workbookCreateAfter: () => {
                    sheetZoomUpdate(this.sheetIdValue+'_total');
                    sheetScrollUpdate(this.sheetIdValue+'_total');

                    setTimeout(() => {
                        Preloader.remove();
                    }, 100);
                },
                sheetMouseup: function (cell, position, sheetFile, moveState) {
                    self.element.dispatchEvent(new CustomEvent("totalCellResizer:sheetMouseUp", {
                        detail: {
                            cell: cell,
                            position: position,
                            sheetFile: sheetFile,
                            moveState: moveState
                        },
                        bubbles: true
                    }));
                },
            },
            data: JSON.parse(this.spreadsheetTarget.dataset.data),
            showtoolbar: false,
            showinfobar: false,
            showstatisticBar: true,
            lang: 'ru',
            cellRightClickConfig: {
                copy: false,
                copyAs: false,
                paste: false,
                insertRow: false,
                insertColumn: false,
                deleteRow: false,
                deleteColumn: false,
                deleteCell: false,
                hideRow: false,
                hideColumn: false,
                rowHeight: false,
                columnWidth: false,
                clear: false,
                matrix: false,
                sort: false,
                filter: false,
                chart: false,
                image: false,
                link: false,
                data: false,
                cellFormat: false
            },
            showsheetbar: true,
            showsheetbarConfig: {
                add: false,
                menu: false
            },
            enableAddRow: false
        }

        luckysheet.destroy();
        luckysheet.create(options);
    }
}