import {Controller} from "@hotwired/stimulus";
import {Modal} from "bootstrap";

export default class extends Controller {
    connect() {
        this.modal = new Modal(this.element);

        this.element.addEventListener('show.bs.modal', () => {
            this.modal.handleUpdate();
        })
    }

    show() {
        this.modal.show();
    }
}
