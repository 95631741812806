import {Controller} from '@hotwired/stimulus';
import Preloader from '../../components/preloader';
import Modal from "../../components/modal";
import {beforeUpdate,rangePasteBefore} from "../../components/luckysheet/cellupdater";
import {sheetScrollUpdate, sheetZoomUpdate} from "../../components/luckysheet/sheetupdater";
import {handleCellDropdowns} from "../../components/luckysheet/dropdownhandler";
import {cellRightClickConfig} from "../../components/luckysheet/defaultconfig";

export default class extends Controller {
    sheet;
    eventSource;
    accordionStorageId = 'media_plan_sheet_accordion_collapsed';
    failedConnection = null;

    static get targets () {
        return [
            'spreadsheet',
            'settingsAccordion',
            'settingsButtons',
            'updateBtn'
        ];
    }

    static values = {
        loadUrl: String,
        updateUrl: String,
        gridKey: String,
        token: String,
        userId: Number,
        mercureUrl: String,
    }

    connect() {
        this.failedConnection = null;
        luckysheet.destroy();

        Preloader.create();
        this.bindAccordion();
        handleCellDropdowns()
        this.handlePageBlock();

        luckysheet.create(this.getOptions());
    }

    onRefreshBtnClick() {
        this.disconnect();
        this.connect();
    }

    disconnect() {
        this.eventSource.close();
        luckysheet.destroy();
    }

    handlePageBlock() {
        this.eventSource = new EventSource(this.mercureUrlValue);

        this.eventSource.onmessage = function (message) {
            const json = JSON.parse(message.data);

            if ((json.id === 'block-page' && self.userIdValue !== json.data.from) ||
                (json.id === 'block-page-user' && self.userIdValue === json.data.to)) {
                const modal = new Modal(json.data.title,
                    json.data.message,
                    {
                        type: 'alert',
                        centered: true,
                        okLabel: 'Обновить',
                        cancelLabel: false,
                        closeButton: false,
                        callback: () => {
                            document.location.reload();
                        },
                    });
                modal.show();
            }
        }
    }

    bindAccordion() {
        const target = this.settingsAccordionTarget;
        const button = this.settingsButtonsTarget;

        target.addEventListener('hidden.bs.collapse', () => {
            localStorage.setItem(this.accordionStorageId, '0');
        });

        target.addEventListener('shown.bs.collapse', () => {
            localStorage.setItem(this.accordionStorageId, '1');
        });

        if (localStorage.getItem(this.accordionStorageId) === '1') {
            target.classList.add('show');
            button.classList.remove('collapsed');
        }
    }

    getOptions() {
        const self = this;

        return {
            container: this.spreadsheetTarget.id,
            gridKey: this.gridKeyValue,
            showinfobar: false,
            forceCalculation: false,
            allowUpdate: true,
            loadUrl: this.loadUrlValue,
            updateUrl: this.updateUrlValue,
            meta: { token: this.tokenValue },
            defaultFontSize: 9,
            lang: 'ru',
            hook: {
                cellUpdateBefore: beforeUpdate,
                rangePasteBefore: rangePasteBefore,
                workbookCreateAfter: () => {
                    sheetZoomUpdate(this.gridKeyValue);
                    sheetScrollUpdate(this.gridKeyValue);
                },
                cooperativeMessage: (data) => {
                    if (!data.data) {
                        return;
                    }

                    data = JSON.parse(data.data);

                    if (data.t && data.t === 'mv') {
                        return;
                    }

                    debug(data, true);
                },
                cooperativeSendMessage: (data) => {
                    if (data.t && data.t === 'mv') {
                        return;
                    }

                    debug(data, false);
                },
                onWebSocketOpen: () => {
                    setTimeout(() => {
                        Preloader.remove();
                    }, 100);
                },
                onWebSocketError: (data) => {
                    if (!data.retry) {
                        setTimeout(() => {
                            Preloader.remove();
                        }, 100);

                        self.failedConnection = true;
                    } else {
                        self.failedConnection = false;
                    }
                },
                onWebSocketClosed: (data) => {
                    if (data.code === 1000 || data.code === 1001) {
                        return;
                    }

                    if (self.failedConnection === null || self.failedConnection === true) {
                        const modal = new Modal('Соединение разорвано (#' + data.code + ')',
                            'Нажмите "Обновить", чтобы продолжить работу с таблицей.',
                            {
                                type: 'confirm',
                                centered: true,
                                yesLabel: 'Обновить',
                                cancelLabel: false,
                                closeButton: false,
                                callback: () => {
                                    document.location.reload();
                                },
                            }
                        );

                        modal.show();
                    }
                }
            },
            showtoolbarConfig: {
                undoRedo: true,
                paintFormat: false,
                currencyFormat: false,
                percentageFormat: false,
                numberDecrease: false,
                numberIncrease: false,
                moreFormats: false,
                font: false,
                fontSize: false,
                bold: false,
                italic: false,
                strikethrough: false,
                underline: false,
                textColor: false,
                fillColor: false,
                border: false,
                mergeCell: false,
                horizontalAlignMode: false,
                verticalAlignMode: false,
                textWrapMode: false,
                textRotateMode: false,
                image: false,
                link: false,
                chart: false,
                postil: false,
                pivotTable: false,
                function: false,
                frozenMode: false,
                sortAndFilter: false,
                conditionalFormat: false,
                dataVerification: false,
                splitColumn: false,
                screenshot: false,
                findAndReplace: false,
                protection: false,
                print: false,
                exportXlsx: false
            },
            cellRightClickConfig: cellRightClickConfig,
            showsheetbar: false,
            showsheetbarConfig: {
                add: false,
                menu: false
            },
            showstatisticBar: true,
            enableAddRow: false
        }
    }
}

function debug(data, received) {
    let title = 'Received('+data.t+'): ';

    if (data.r && data.c) {
        title = 'Received(' + data.t + ' ' + data.r + ':' + data.c + '): ';
    } else if (data.t === "fc") {
        const v = JSON.parse(data.v);

        if (v) {
            title = 'Received(' + data.t + ' ' + v.r + ':' + v.c + '): ';
        } else {
            title = 'Received(' + data.t + '): ';
        }
    } else if (data.k) {
        title = 'Received('+data.t+' '+data.k+'): ';
    }

    if (!received) {
        title = title.replace('Received', 'Sent');
    }

    console.info('%c'+title, (received ? 'color:green' : 'color:red'));
    console.info(data);
}