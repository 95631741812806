import { Controller } from "@hotwired/stimulus";
import Modal from "../../components/modal";

export default class extends Controller {
    confirmDelete(event) {
        event.preventDefault();

        const { id, title } = event.params;

        const confirmModal = new Modal('Подтверждение', 'Действительно хотите удалить бриф "' + title + '"?', {
            yesLabel: 'Удалить',
            callback: async function () {
                let response = await fetch('/brief/delete/'+id, {
                    method: 'POST',
                });

                if (response.status === 204) {
                    document.location.reload();
                } else {
                    const text = await response.text();
                    (new Modal('Ошибка!', text, { type: "alert"})).show();
                }
            }
        });

        confirmModal.show();
    }
}