import {Controller} from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
    select;

    connect() {
        const form = this.element.getElementsByTagName('form')[0];
        const select = form.getElementsByTagName('select')[0];

        this.select = new TomSelect(select, {
            persist: false,
            create: false,
        })

        select.addEventListener('change', function() {
            form.submit();
        });
    }

    disconnect() {
        this.select.destroy();
    }
}