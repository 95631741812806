import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import {createCallback, getPluginsDefaults, getRenderDefaults, onItemAddDefault} from "../../components/tomselect2";

export default class extends Controller {
    static get targets() {
        return ["channels", "services"];
    }

    connect() {
        const self = this;

        this.services = new TomSelect(this.servicesTarget, {
            plugins: getPluginsDefaults(),
            create: createCallback('/reference/create/service', function() {
                return {
                    channelId: self.channels.getValue()[0]
                }
            }),
            render: getRenderDefaults(),
            onItemAdd: onItemAddDefault(),
        });

        this.channels = new TomSelect(this.channelsTarget, {
            maxItems: 1,
            onChange: function () {
                self.fetchServices();
            },
            render: getRenderDefaults(),
            onItemAdd: onItemAddDefault(),
            plugins: getPluginsDefaults(),
        });

        this.services.clearOptions();

        this.fetchServices();
    }

    disconnect() {
        this.services.destroy();
        this.channels.destroy();
    }

    fetchServices() {
        const self = this;

        fetch('/brief/services?channels=' + JSON.stringify(this.channels.getValue()))
            .then(response => response.json())
            .then(json => {
                self.updateServices(json);
            })
            .catch(() => {
            })
    }

    updateServices (data) {
        const currentServices = this.services.getValue();
        let filteredServices = [];

        currentServices.forEach((value) => {
            const inArray = data.find(function (element) {
                return element.id.toString() === value;
            });

            if (inArray) {
                filteredServices.push(value);
            }
        });

        this.services.clearOptions();

        data.forEach((item) => {
            const id = item.id;
            const name = item.name;

            this.services.addOption({title: name, id: id})
        });

        this.services.setValue(filteredServices);
    }
}