import {Controller} from "@hotwired/stimulus";
import {totalSizeUpdate} from "../../components/luckysheet/sheetupdater";

export default class extends Controller {
    static values = {
        sizeUpdateUrl: String,
        csrfToken: String,
    }

    connect() {
        this.element.addEventListener("totalCellResizer:sheetMouseUp", this.sheetMouseUp.bind(this));
    }

    disconnect() {
        this.element.removeEventListener("totalCellResizer:sheetMouseUp", this.sheetMouseUp.bind(this));
    }

    sheetMouseUp({ detail: { cell, position, sheetFile, moveState } }) {
        if (!moveState.colsChangeSize && !moveState.rowsChangeSize) {
            return;
        }

        let sizeUpdateUrl = this.sizeUpdateUrlValue;
        let csrfToken = this.csrfTokenValue;

        setTimeout(() => {
            let template = $('#media_plan_total_template_name').val();
            let columnlen = sheetFile.config.columnlen;
            let rowlen = sheetFile.config.rowlen;
            let config = {[sheetFile.name]: {
                    'columnlen': Object.assign({}, columnlen),
                    'rowlen': rowlen,
                }};

            totalSizeUpdate(config, template, sizeUpdateUrl, csrfToken);
        }, 100);
    }
}