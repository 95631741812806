export function beforeUpdate(r, c, newValue) {
    saveValue(r, c, newValue);
    return false;
}

export function rangePasteBefore(range, data) {
    let html = (new DOMParser()).parseFromString(data, 'text/html');
    let trList = $(html.body).find('tr');
    let spanList = $(html.body).find('span');

    $(html.body).find('br').each(function () {
        $(this).replaceWith('\n');
    });

    if (1 === spanList.length) {
        saveValue(range[0].row_focus, range[0].column_focus, spanList[0].textContent);
        return false;
    }

    if (0 === trList.length) {
        saveValue(range[0].row_focus, range[0].column_focus, data);
        return false;
    }

    let r = range[0].row_focus;
    trList.each(function () {
        let c = range[0].column_focus;
        $(this).children().each(function () {
            saveValue(r, c, $(this).text().trim());
            c++;
        });
        r++;
    });

    return false;
}

function isProtected(r, c) {
    const rangeList = luckysheet.getConfig().authority.allowRangeList;

    for (const i in rangeList) {
        const sqref = rangeList[i].sqref.split(':'); // e.g.: ['B5', 'B50']

        const start = coordinateFromString(sqref[0]);
        const end = coordinateFromString(sqref[1]);
        const startRow = start[1];
        const startCol = columnIndexFromString(start[0]);
        const endRow = end[1];
        const endCol = columnIndexFromString(end[0]);

        if (r + 1 >= startRow && r + 1 <= endRow && c + 1 >= startCol && c + 1 <= endCol) {
            return true;
        }
    }

    return false;
}

function coordinateFromString(cellAddress) {
    const match = /^(?<col>\$?[A-Z]{1,3})(?<row>\$?\d{1,7})$/i.exec(cellAddress);

    if (null === match) {
        throw new Error('Invalid cell coordinate: ' + cellAddress)
    }

    return [match.groups.col, match.groups.row];
}

function columnIndexFromString(columnAddress) {
    const columnLookup = { 'A' : 1, 'B' : 2, 'C' : 3, 'D' : 4, 'E' : 5, 'F' : 6, 'G' : 7, 'H' : 8, 'I' : 9, 'J' : 10,
        'K' : 11, 'L' : 12, 'M' : 13, 'N' : 14, 'O' : 15, 'P' : 16, 'Q' : 17, 'R' : 18, 'S' : 19,
        'T' : 20, 'U' : 21, 'V' : 22, 'W' : 23, 'X' : 24, 'Y' : 25, 'Z' : 26,
        'a' : 1, 'b' : 2, 'c' : 3, 'd' : 4, 'e' : 5, 'f' : 6, 'g' : 7, 'h' : 8, 'i' : 9, 'j' : 10,
        'k' : 11, 'l' : 12, 'm' : 13, 'n' : 14, 'o' : 15, 'p' : 16, 'q' : 17, 'r' : 18, 's' : 19,
        't' : 20, 'u' : 21, 'v' : 22, 'w' : 23, 'x' : 24, 'y' : 25, 'z' : 26 };

    if (typeof columnAddress !== 'string') {
        throw new Error('Column string index can not be '+columnAddress+' longer than 3 characters');
    }

    if (typeof columnAddress[0] !== 'undefined') {
        if (typeof columnAddress[1] === 'undefined') {
            return columnLookup[columnAddress[0]];
        } else if (typeof columnAddress[2] === 'undefined') {
            return columnLookup[columnAddress[0]] * 26 + columnLookup[columnAddress[1]];
        } else if (typeof columnAddress[3] === 'undefined') {
            return columnLookup[columnAddress[0]] * 676 + columnLookup[columnAddress[1]] * 26 + columnLookup[columnAddress[2]];
        }
    }

    throw new Error('Column string index can not be '+columnAddress+' longer than 3 characters');
}

function saveValue(r, c, value) {
    if (false === isProtected(r, c)) {
        return;
    }

    value = parseValue(r, c, value)

    luckysheet.setCellValue(r, c, value, { triggerUpdated: false, triggerBeforeUpdate: false });
}

function parseValue(r, c, newValue) {
    let value = newValue;
    let ct = luckysheet.getCellValue(r, c, { type: 'ct' });

    if (!ct) {
        ct = { fa: 'General', t: 'g'};
    }

    const format = ct.fa || '';
    const type = ct.t || '';

    const isNumberType = 'n' === type;
    const isPercentFormat = format.endsWith('%');

    if (value.length > 0) {
        if (isNumberType) {
            value = value
                .replace(',', '.')
                .replace('%', '')
                .replaceAll(' ', '')
                .replaceAll(' ', '')
                .replace('р.', '')
            ;

            if (isPercentFormat) {
                value /= 100;
            }

            value = parseFloat(value);

            if (isNaN(value)) {
                value = 'N/A';
            }
        } else {
            return {
                ct: {
                    fa: 'General',
                    t: 'inlineStr',
                    s: [{v: value, fs: 9, fc: "#444444"}],
                },
                v: null,
                m: null
            };
        }
    }

    return {
        v: value,
        ct: ct,
    };
}
