import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
    tippy;

    connect() {
        this.tippy = tippy(this.element, {
            content(reference) {
                const title = reference.getAttribute('title');
                reference.removeAttribute('title');

                return title;
            },
            allowHTML: true
        });
    }

    disconnect() {
        this.tippy.destroy();
    }
}