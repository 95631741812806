import {Controller} from "@hotwired/stimulus";
import App from '../oneui/_js/main/app';

export default class extends Controller
{
    connect() {
        document.getElementsByTagName('html')[0].className = '';
        window.One = new App();
    }

    disconnect() {
        window.One = undefined;
    }
}